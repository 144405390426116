<template>
  <div>
    <v-navigation-drawer v-model="sidebar" light floating clipped app width="350" height="100%"
      color="rgba(255,255,255,0.96)" class="mt-1 mr-1 pa-10 rounded-lg rounded-l-0 sidebar-drawer">
      <v-list flat light>
        <v-list-item-group v-model="tab" light>
          <v-list-item color="themepurple" v-for="(item, i) in menuitems" :key="i" :disabled="i == tab">
            <v-list-item-title :key="i">
              {{ item.step }} {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-spacer></v-spacer>
      </v-list>

      <p class="bottom-text">{{ form.title }}</p>
    </v-navigation-drawer>
    <v-app-bar-nav-icon class="left-top-absolute ma-4" @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>
    <v-main class="pa-0 ml-1 pt-0 pa-15">

      <h1 class="mb-5">Transformatiemotie</h1>
      <v-row>
        <v-col cols="12" md="6">
          <label for="">Aan </label>
          <v-text-field v-model="form.to" background-color="#E8F4F6" class="theme-input" solo
            placeholder="voorbeeld@email.nl" title="Aan" :readonly="!edit"></v-text-field>

          <label for="">van </label>
          <v-text-field v-model="form.from" background-color="#E8F4F6" class="theme-input" solo :readonly="!edit"
            placeholder="voorbeeld@email.nl" title="Van"></v-text-field>

          <label for="">Afdeling </label>
          <v-text-field v-model="form.department" background-color="#E8F4F6" class="theme-input" solo :readonly="!edit"
            placeholder="Afdeling" title="Afdeling"></v-text-field>

          <label for="">CC </label>
          <v-text-field v-model="form.cc" background-color="#E8F4F6" class="theme-input" solo :readonly="!edit"
            placeholder="voorbeeld@email.nl" title="CC"></v-text-field>
          <v-divider class="mb-10" :style="'border-color:#E8F4F6'"></v-divider>

          <label for="">Titel </label>
          <v-text-field v-model="form.title" background-color="#E8F4F6" class="theme-input" solo :readonly="!edit"
            title="Titel"></v-text-field>
          <v-divider class="mb-10" :style="'border-color:#E8F4F6'"></v-divider>

          <p>
            De Groninger gemeenten werken onder de paraplu van de regiovisie
            (NvO) aan de verdere verbetering van de jeugdhulp. De kern van
            de regiovisie is de is de verdere beweging die de gemeenten in
            het zorglandschap willen maken, samengevat in zes
            ontwikkelopgaven:
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-row class="my-10 py-15">
            <v-col cols="6" md="4">
              <div class="text-center">
                <v-card class="rounded" color="transparent" elevation="0">
                  <v-img src="../../assets/transformation-motion/flower.svg" class="mx-auto" max-width="225"></v-img>
                </v-card>
                <p>
                  Alle jeugdigen krijgen de kans zich positief te ontwikkelen.
                </p>
              </div>
            </v-col>
            <v-col cols="6" md="4">
              <div class="text-center">
                <v-card class="rounded" color="transparent" elevation="0">
                  <v-img src="../../assets/transformation-motion/eco.svg" class="mx-auto" max-width="225"></v-img>
                </v-card>
                <p>
                  Jeugdigen worden beschermd als hun ontwikkeling gevaar loopt.
                </p>
              </div>
            </v-col>

            <v-col cols="6" md="4">
              <div class="text-center">
                <v-card class="rounded" color="transparent" elevation="0">
                  <v-img src="../../assets/transformation-motion/house.svg" class="mx-auto" max-width="225"></v-img>
                </v-card>
                <p>Jeugdigen groeien zo thuis en veilig mogelijk op.</p>
              </div>
            </v-col>

            <v-col cols="6" md="4">
              <div class="text-center">
                <v-card class="rounded" color="transparent" elevation="0">
                  <v-img src="../../assets/transformation-motion/lock.svg" class="mx-auto" max-width="225"></v-img>
                </v-card>
                <p>De toegang voor (jeugdhulp) kan zijn taak goed uitvoeren.</p>
              </div>
            </v-col>

            <v-col cols="6" md="4">
              <div class="text-center">
                <v-card class="rounded" color="transparent" elevation="0">
                  <v-img src="../../assets/transformation-motion/rocket.svg" class="mx-auto" max-width="225"></v-img>
                </v-card>
                <p>
                  Bij de inzet van jeugdhulp gelden een aantal leidende
                  principes.
                </p>
              </div>
            </v-col>

            <v-col cols="6" md="4">
              <div class="text-center">
                <v-card class="rounded" color="transparent" elevation="0">
                  <v-img src="../../assets/transformation-motion/thumps-up.svg" class="mx-auto" max-width="225"></v-img>
                </v-card>
                <p>
                  De ondersteuning, begeleiding en behandeling voor jeugdigen en
                  gezinnen is passend voor alle doelgroepen en van goede
                  kwaliteit.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <p>
            De Transformatieagenda Jeugdhulp vormt een belangrijke pijler in
            deze beweging, omdat projecten, pilots en activiteiten vanuit
            Groningen geeft thuis! bijdragen aan deze ontwikkelopgaven én
            aanvullende inzichten bieden over duurzame oplossingen voor de
            lange termijn.
          </p>
          <v-divider class="mt-10 mb-10" :style="'border-color:#E8F4F6'"></v-divider>
          <label for="">Constaterende dat: </label>
          <p>Dit zien wij als knelpunten</p>
          <v-textarea v-model="form.bottlenecks" background-color="#E8F4F6" class="theme-input" solo :readonly="!edit"
            title="Dit zien wij als knelpunten"></v-textarea>

          <p>Dit zien wij als kansen</p>
          <v-textarea v-model="form.opportunities" background-color="#E8F4F6" class="theme-input" solo :readonly="!edit"
            title="Dit zien wij als kansen"></v-textarea>

          <label for="">Daarom zijn wij van mening dat:</label>
          <v-textarea v-model="form.opinion" background-color="#E8F4F6" class="theme-input" solo :readonly="!edit"
            title="Daarom zijn wij van mening dat:"></v-textarea>

          <label for="">Daarom verzoeken wij het SOO om:</label>
          <v-textarea v-model="form.requests" background-color="#E8F4F6" class="theme-input" solo :readonly="!edit"
            title="Daarom verzoeken wij het SOO om:"></v-textarea>

          <label for="">Concreet stellen wij voor dat het SOO besluit:</label>
          <v-textarea v-model="form.suggestion" background-color="#E8F4F6" class="theme-input" solo :readonly="!edit"
            title="Concreet stellen wij voor dat het SOO besluit:"></v-textarea>

          <label for="">Meetbare veranderingen:</label>

          <v-textarea v-model="form.measurable_changes" background-color="#E8F4F6" class="theme-input" solo :readonly="!edit"
            title="Meetbare veranderingen:"></v-textarea>
        </v-col>
      </v-row>

      <v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined v-if="edit"
              color="themepurple" @click="submit('concept')"><v-icon>fas fa-save</v-icon></v-btn>
            <v-btn class="mr-3 btn" disabled outlined @click="prev()" color="themepurple">Terug</v-btn>
            <v-btn class="btn" @click="nextdialog = true" dark color="themepurple">Volgende</v-btn>
          </v-col>
        </v-row>
      </v-footer>



      <!-- Download / submit dialog -->
      <v-dialog v-model="nextdialog" content-class="theme-popup">
        <div class="text-right">
          <v-btn @click="nextdialog = false" icon class="" elevation="0" color="themepurple">
            <v-icon>fas fa-times </v-icon>
          </v-btn>
        </div>
        <div v-if="(senddate = true)">
          <div class="text-center pb-5">
            <h3>Weet je zeker dat je de transformatie motie wil verzenden?</h3>
          </div>
          <div class="text-center">
            <p>
              Er word een mail verstuurd naar de opgegeven emailadressen.
            </p>
          </div>
          <div class="text-center pt-5">
            <v-btn class="btn text-primary mr-3" color="#fff">Downloaden</v-btn>
            <v-btn class="btn" color="themepurple" dark>Inleveren</v-btn>
          </div>
        </div>
        <div v-else>
          <div class="text-center pb-5">
            <h3>Verstuurd</h3>
          </div>
          <div class="text-center">
            <p>Document is verstuurd naar organisator.</p>
          </div>
        </div>
      </v-dialog>
    </v-main>
  </div>
</template>

<script>
import api from "../../services/api";
import config from '../../config';
import { mapGetters } from 'vuex'
// import topmenubar from "@/components/topmenubar.vue";
export default {
  // components: { topmenubar },
  data() {
    return {
      projects: [],
      sidebar: true,
      ready: true,
      nextdialog: false,
      edit:false,
      senddata: false,
      id: 0,
      tab: 0,
      form: {
        projects:[],
        to: "",
        from: "",
        department: "",
        cc: "",
        title: "",
        bottlenecks: "",
        opportunities: "",
        opinion: "",
        requests: "",
        suggestion: "",
        measurable_changes: "",
      },
      menuitems: [
        {
          title: "Transformatiemotie",
        },

      ],
    };
  },
  computed: {
    ...mapGetters({ user: 'getuser' }),
  },
  methods: {
    getProjects() {
      api.get(`${config.url.api}/v1/projects/list`).then((res) => {
        if (res.data.success) {
          this.projects = res.data.data;
          const urlParams = new URLSearchParams(window.location.search);
          var projects = urlParams.get('projects');
          if (projects != null) {
            projects = projects.split(",");
            projects.forEach(p => {
              if (p !== "") {
                this.projects.forEach(project => {
                  
                  if (project.id == parseInt(p)) {
                    this.form.projects.push(project)
                  }
                });
              }
            });
          }
        }
      }).catch((err) => {
        switch (err.response.status) {
          default:
            this.$toast.error('Fout bij het ophalen van je projecten.')
            break;
        }
      })
    },

    getMotions() {
      api.get(`${config.url.api}/v1/motions/${this.$route.params.id}`).then((res) => {
        if (res.data.success) {
          this.form = res.data.data.data;
          this.form.title = res.data.data.title;
          this.edit = this.user.role == "PM" || this.user.role == "OG";
          this.ready = true;
          if (this.$route.params.id == undefined) {
            this.$router.push("/transformatie-motie/" + this.$route.params.id)
          }
        } else {
          this.$router.push("/transformatie-motie/" + this.$route.params.id)
        }
      }).catch((err) => {
        switch (err.response.status) {
          case 401:
            this.$router.push("/pagina-niet-gevonden")
            break;
          case 404:
            this.$router.push("/pagina-niet-gevonden")
            break;
          default:
            this.$toast.error('Fout bij het ophalen van de motie.')
            break;
        }
      })
    },
    submit(type) {
      var data = {
        data: this.form,
        type: type
      };
      var patchslug = this.$route.params.id === "nieuw" ? 0 : this.$route.params.id;
      api.patch(`${config.url.api}/v1/motions/${patchslug}`, data).then((res) => {
        if (res.data.success) {
          //redirect to the newly created recource.
          switch (type) {
            case "concept":
              this.$toast.success('Concept motie succesvol geupdate.');
              break;
            case "save":
              this.$toast.success('Transformatie motie succesvol verzonden.');
              this.nextdialog = false;
              break;
          }

          if (patchslug == 0) {
            this.$router.push("/transformatie-motie/" + res.data.slug)
          }

        } else {
          this.$toast.error('Er ging iets mis bij het opslaan van je motie.')
        }
      }).catch((err) => {
        switch (err.response.status) {
          case 401:
            this.$toast.error('Je hebt geen toegang tot deze motie.')
            this.$router.push("/pagina-niet-gevonden")
            break;
          default:
            this.$toast.error(`Er is iets fout gegaan (${err.response.status})`)
            break;
        }
      })
    },

    next() {
      this.tab++;
      window.scrollTo(0, 0);
    },
    prev() {
      this.tab--;
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getProjects();
    if (this.$route.params.id != "nieuw") {
      this.getMotions();
    } else {
      this.edit = true;
    }
  },
};
</script>
